import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Marquee from 'react-fast-marquee';
import { useInView } from 'framer-motion';
import Container from '../../../primitives/grid/container';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import Background from '../../../primitives/backgrounds';
import DatoAsset from '../../../primitives/asset';
import { breakpoints } from '../../../primitives/tokens';
import AspectRatio from '../../../primitives/aspect-ratio';
import { useLanguage } from '../../../language/language-provider';
import Link, { getLink } from '../../../primitives/link';
import VisuallyHidden from '../../../primitives/visually-hidden';

export type LogoMarqueeProps = Queries.LogoMarqueePropsFragment;

const LogoMarquee = ({ showGradient, hideTitle }: LogoMarqueeProps): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    const inView = useInView(ref);
    const { language } = useLanguage();

    const data = useStaticQuery<Queries.LogoMarqueeQuery>(graphql`
        query LogoMarquee {
            datoCmsGlobal {
                logoMarqueeText {
                    blocks
                    links
                    value
                }
            }
            au: datoCmsIconCarousel(locale: "en-AU") {
                icons {
                    title
                    logo {
                        ...DatoCmsInternalAsset
                    }
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
            uk: datoCmsIconCarousel(locale: "en-GB") {
                icons {
                    title
                    logo {
                        ...DatoCmsInternalAsset
                    }
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
            us: datoCmsIconCarousel(locale: "en-US") {
                icons {
                    title
                    logo {
                        ...DatoCmsInternalAsset
                    }
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
        }
    `);

    const logoNodes = data[(language as 'au' | 'uk' | 'us')].icons;

    const logosDuped = logoNodes
        .concat(logoNodes)
        .concat(logoNodes);

    const text = data?.datoCmsGlobal?.logoMarqueeText;

    return (
        <div
            css={{
                backgroundColor: '#fff',
                marginBottom: hideTitle ? '0px' : '180px',
                [breakpoints.mb]: {
                    marginBottom: hideTitle ? '0px' : '92px',
                },
            }}
        >
            <div
                ref={ref}
                css={{
                    paddingTop: '100px',
                    paddingBottom: !hideTitle ? '0px' : '100px',
                    [breakpoints.mb]: {
                        paddingTop: '68px',
                        paddingBottom: !hideTitle ? '0px' : '68px',
                    },
                }}
            >
                {!hideTitle && (
                    <Container css={{
                        paddingBottom: '100px',
                        [breakpoints.mb]: {
                            paddingBottom: '48px',
                        },
                    }}
                    >
                        <Typography
                            fontSize={{
                                dt: 4053,
                                mb: 2429,
                            }}
                            fontWeight="light"
                            css={{ textAlign: 'center' }}
                        >
                            <CustomStructuredTextRenderer data={text} />
                        </Typography>
                    </Container>
                )}
                <Marquee
                    speed={45}
                    gradientColor={[248, 248, 248]}
                    gradientWidth={40}
                    style={{ overflow: 'hidden' }}
                    play={inView}
                >
                    <div>
                        {logosDuped.map(x => (
                            <div css={{
                                display: 'inline-block',
                                width: '160px',
                                maxHeight: '48px',
                                marginRight: '140px',
                                position: 'relative',
                                [breakpoints.mb]: {
                                    width: '80px',
                                    marginRight: '50px',
                                },
                            }}
                            >
                                {x?.link?.length > 0 && (
                                    <Link
                                        to={getLink(x?.link?.[0]?.link)}
                                        css={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            zIndex: 3,
                                        }}
                                    >
                                        <VisuallyHidden>{x.title}</VisuallyHidden>
                                    </Link>
                                )}
                                <div>
                                    <AspectRatio
                                        breakpoints={{
                                            dt: { width: x.logo?.width, height: x.logo?.height },
                                        }}
                                        css={{ width: '100%', height: '100%' }}
                                    >
                                        <DatoAsset
                                            css={{
                                                height: '100%',
                                                objectFit: 'contain',
                                                maxHeight: '48px',
                                                [breakpoints.mb]: {
                                                    maxHeight: '24px',
                                                },
                                            }}
                                            objectFit="contain"
                                            objectPosition="center center"
                                            asset={x.logo}
                                        />
                                    </AspectRatio>
                                </div>
                            </div>
                        ))}
                    </div>
                </Marquee>
            </div>
        </div>
    );
};

export default LogoMarquee;

export const query = graphql`
    fragment LogoMarqueeProps on DatoCmsLogoMarquee {
        showGradient
        hideTitle
    }
`;
